@media only screen and (max-width: 991px) {
  .dropdown-menu.show {
    width: 100%;
  }
  .Pagenotfound .page-not-found-content h2 {
    font-size: 22px;
  }
  .Pagenotfound .page-not-found-content .page-not-found-to-go-back {
    margin-top: 10px;
    padding: 10px;
    font-size: 12px;
    border-radius: 6px;
  }
  .background-img-notfound {
    height: auto !important;
  }
  .Profile-dropdown.join-now.nav-link {
    margin-left: 0 !important;
  }
  .accordion-body {
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .desktop-view {
    display: none !important;
  }
  .navbar-brand img {
    width: 150px !important;
    height: auto !important;
    margin-left: 10px;
  }
  .navbar-right .join-now {
    text-align: center;
    margin-right: 10px;
  }
  .navbar-right .Search_bar {
    width: 30px;
  }
  .navbar-right {
    display: flex;
    align-items: center;
  }
  .btn-navbar img {
    width: 30px;
  }
  .menu-drop-down-popup:before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    background: #00000091;
    z-index: 1;
    top: 0;
    left: 0;
  }
  .navbar {
    z-index: 2;
  }
  ._darkMode_ .navbar {
    background: #f5f5f5;
  }
  .toast-body {
    padding: var(--bs-toast-padding-x);
    word-wrap: break-word;
    background: #fff;
    position: relative;
    z-index: 100;
    border-radius: 0 8px 8px 0;
  }
  .menu-drop-down-popup {
    border-radius: 0px 8px 8px 0;
    margin-top: 2px;
    position: absolute;
  }
  .Accordion-Accordion_style button {
    font-size: 16px;
    font-weight: 400;
  }
  .Accordion-Accordion_style {
    padding: 0 20px;
  }
  .Accordion-Accordion_style .accordion-button::after {
    background-size: 1rem;
  }
  /* ._lightMode_ .toast-body {
        background: #0a070700;
    } */
  .mobile-view .hero-h1 h1 {
    font-size: 32px;
    text-align: center;
    margin-top: 30px;
  }
  .mobile-view .hero-left p {
    font-size: 18px;
  }
  .mobile-view .hreo-button-style {
    width: 65px;
    height: 40px;
    margin: auto;
    font-size: 16px;
    align-items: center;
    margin-right: auto;
    margin-bottom: auto;
  }
  .mobile-view .hreo-button {
    margin-top: 3rem;
  }
  .mobile-view .hreo-button-bottom {
    max-width: 350px;
    width: 100%;
    height: 47px;
    margin-top: 3rem !important;
    margin-bottom: 0rem;
    margin: auto;
  }
  .mobile-view .hreo-button-bottom a {
    font-size: 18px;
  }
  .mobile-view.latest-courses {
    background: #0000;
  }
  .mobile-view .accordion-body {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    overflow: hidden;
    position: relative;
  }
  .mobile-view .accordion-button {
    border-radius: 8px 8px 0px 0px;
    background: #e8e8e8;
  }
  .mobile-view .accordion-button:not(.collapsed) {
    background-color: #e8e8e8;
  }
  ._lightMode_ .mobile-view .accordion-button {
    border-radius: 8px 8px 0px 0px;
    background: #454545;
  }
  ._lightMode_ .mobile-view .courses-box-title {
    border-radius: 0px 0px 10px 10px;
    background: #454545;
    margin-top: 2px;
  }
  ._lightMode_ .mobile-view .courses-box {
    background: #0000;
  }
  .mobile-view .courses-button-style {
    font-size: 14px;
  }
  .mobile-view .courses-box-content-button {
    padding: 8px 8px;
    margin: 10px 10px 10px 0;
    width: 100%;
    max-width: 155px;
    min-width: auto;
  }
  .mobile-view .courses-box-title {
    border-radius: 0px 0px 10px 10px;
    background: #e8e8e8;
    margin-top: 2px;
  }
  .mobile-view .mobile-view-brand {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .mobile-view .mobile-view-footer {
    display: flex;
    justify-content: space-between;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin: 60px 0;
  }
  .mobile-view .brand-box img {
    width: 60px;
  }
  .mobile-view .brand-box p {
    font-size: 16px;
  }
  .mobile-view.brend {
    background: #0000;
  }
  ._lightMode_ .mobile-view.brend {
    background: #e3f3d7;
  }
  .mobile-view .brand-box-2 {
    margin-bottom: 0rem;
  }
  .mobile-view .charting-right-content {
    margin-top: 30px;
  }
  .mobile-view .speaks-right-content h4 {
    margin-top: 30px;
  }
  .mobile-view .footer-logo p {
    font-size: 18px;
  }
  .mobile-view .social-icon {
    justify-content: center;
  }
  .mobile-view .queke-link ul li a {
    font-size: 16px;
  }
  ._lightMode_ .mobile-view .lite {
    display: block;
  }
  .top-header.mobile-view {
    background: #0000;
  }
  .mobile-view .filter_myprofile_top ul button {
    font-size: 14px;
    margin-right: 10px;
    padding: 8px;
    border-radius: 0px 0px 4px 4px;
    background: #efefef;
  }
  .mobile-view .filter_myprofile_top ul .active:before {
    display: none;
  }
  .mobile-view .filter_myprofile_top ul .active {
    background: linear-gradient(
      180deg,
      rgba(240, 240, 240, 0) -12.12%,
      #ececec 100%
    ) !important;
  }
  .mobile-view .file-upload {
    padding: 4px;
    height: 40px;
  }
  .mobile-view .profile_img_upload_input {
    justify-content: space-between;
  }
  .mobile-view .myprofile-password {
    border-bottom: none;
  }
  .mobile-view .myprofile_update_main {
    border-bottom: none;
  }
  .background-remove {
    background: #0000 !important;
  }
  .filter_myprofile_top-text {
    color: #434141;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .filter_myprofile_top-text {
    color: #434141;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .filter_myprofile_top-text span {
    color: #5b9311;
  }
  .mobile-view .btn-primary {
    height: 40px;
    font-size: 16px;
  }
  .mobile-view .myprofile-card p {
    margin-bottom: 30px;
  }
  .Accordion-header-text-style .accordion-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
  .Accordion-header-text-style .accordion-button p {
    margin-bottom: 0;
  }
  .Accordion-header-text-style .accordion-button::after {
    position: absolute;
    right: 5%;
    top: 37%;
  }
  ._lightMode_ .filter_myprofile_top-text {
    color: #fff;
  }
  ._lightMode_ .mobile-view .filter_myprofile_top ul button {
    background: #72746f;
  }
  ._lightMode_ .mobile-view .filter_myprofile_top ul .active {
    background: linear-gradient(
      180deg,
      rgb(91 147 18) -12.12%,
      #5b9312 100%
    ) !important;
    color: #fff !important;
  }
  ._lightMode_ .mobile-view p {
    color: #fff;
  }
  .mobile-view .contactus-page-right-box {
    height: 210px;
    overflow: hidden;
  }
  .mobile-view .contactus-page-left h2 {
    font-size: 42px;
    text-align: center;
  }
  .mobile-view.contactus-page {
    background: #0000;
  }
  .mobile-view .content-page-from .form-control {
    border-bottom: 1px solid #f3f0f0;
    padding-left: 0;
  }
  .mobile-view .mobile-view-from-content-page {
    width: 90%;
    margin: auto;
  }
  .mobile-view .textarea-input {
    border-bottom: 1px solid #eee;
    padding: 0px;
  }
  .mobile-view .login-top-logo img {
    width: 119px;
  }
  .mobile-view .login-from {
    border-radius: 16px;
    border: none !important;
    background: #0000 !important;
    margin: auto;
    margin-top: 4rem;
    padding: 20px;
  }
  .mobile-view .signup-page-mobile-view-top-header {
    color: #000;
    text-align: center;
    font-family: Nunito;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
  .mobile-view .login-from .form-label {
    font-size: 20px;
    font-weight: 300;
  }
  .mobile-view .password {
    justify-content: flex-end;
    margin-top: 8px;
  }
  .mobile-view .or-login-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-view .or-login-button .login-button {
    margin: 0 5px;
  }
  .mobile-view .login-button button img {
    width: 25px;
    margin-right: 4px;
  }
  .mobile-view .or-login-text {
    color: #979797;
    font-family: Nunito;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
  }
  .ap-mobile-view-header {
    background: #0000 !important;
  }
  .mobile-view-top-header-style p {
    margin-left: 0px !important;
  }
  .mobile-view-right img {
    margin-right: 20% !important;
  }
  .mobile-view-content .right-arror-key {
    text-align: end;
    display: flex;
    justify-content: flex-end;
  }
  ._lightMode_ .toast-body {
    background: #2e2e2e;
  }
  ._lightMode_ .toast-body .accordion {
    --bs-accordion-bg: #2e2e2e;
  }
  ._lightMode_ .lite-logo {
    display: none;
  }
  ._lightMode_ .mobile-view .signup-page-mobile-view-top-header {
    color: #fff;
  }
  .ap-heading p {
    margin-left: 10px !important;
  }
  .fixed-accordion {
    margin-right: 0px !important;
    position: unset;
    top: 0 !important;
    width: 100% !important;
    height: auto !important;
    overflow: scroll;
  }
  .question-left {
    margin-right: 0;
    position: sticky;
    top: 0px;
    width: 100%;
    height: auto !important;
    overflow: scroll;
    margin-bottom: 60px;
  }
  .see-why-popup img {
    margin-top: 30px;
  }
  .quiz_bottom_button {
    max-width: 367px;
    width: 100%;
    margin: 10px 0;
  }
  .toast-body .accordion-body {
    padding-left: 1rem !important;
  }
  .mobile-right-page-header {
    display: flex;
    justify-content: center;
  }
  .mobile-right-page-header.accordion-body .nav-item {
    margin-bottom: 10px;
  }
  .unit-button {
    max-width: 100% !important;
  }
  .unit-menu.dropdown-menu.show {
    max-width: 100% !important;
  }
  .filter_question_top ul button {
    width: 100% !important;
    min-width: 75px !important;
  }

  ._darkMode_ .filter_question_top ul button,
  ._lightMode_ .filter_question_top ul button {
    border-radius: 13.5px;
    /* background: #BAE287 !important; */
    padding-bottom: 0;
    /* border-bottom: 0 !important; */
    padding: 0px 0px !important;
    /* border-bottom-color: #0000 !important; */
    font-size: 16px !important;
    margin-right: 20px;
  }
  .question-left a {
    border-bottom: 0.2px solid #000 !important;
  }
  .filter_question_top ul {
    padding-left: 0;
    justify-content: flex-start;
  }
  .popup-button-question-page button {
    height: 30px !important;
    font-size: 11px !important;
    margin-bottom: 18px !important;
  }
  .question-main-box-style {
    padding: 20px 0px !important;
  }
  .popup-button-question-page button {
    max-width: 100px !important;
    margin-right: 8px !important;
  }
  .btn-popup-button-full-img img {
    width: 15px !important;
  }
  .btn-popup-button-full-img {
    padding: 4px !important;
  }
  .question-left a {
    margin-right: 10px;
    display: flex !important;
    width: 35px !important;
    height: 35px !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 6px;
    border: 0.2px solid #000;
    background: #fff;
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.25);
  }
  .mobile-view .question-left {
    display: inline-flex;
    width: auto;
    margin-bottom: 25px;
    padding-bottom: 5px;
  }
  .mobile-view.overflow-x {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 30px;
  }
  .filter_question_top ul {
    position: relative;
  }
  ._darkMode_ .filter_question_top ul button:before,
  ._lightMode_ .filter_question_top ul button:before {
    content: "";
    position: absolute;
    background: #eee;
    border-radius: 50px;
    bottom: -35px;
    left: 25px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
  }
  ._darkMode_ .filter_question_top ul button.active:after,
  ._lightMode_ .filter_question_top ul button.active:after {
    content: "";
    position: absolute;
    /* background: #F00; */
    box-shadow: 0px 0px 2.9px 2px rgba(0, 0, 0, 0.5) inset;
    border-radius: 50px;
    bottom: -31.5px;
    left: 28.5px;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center;
  }

  ._darkMode_ .filter_question_top ul button:nth-child(1).active:after,
  ._lightMode_ .filter_question_top ul button:nth-child(1).active:after {
    background: #3fa81a40;
  }
  ._darkMode_ .filter_question_top ul button:nth-child(2).active:after,
  ._lightMode_ .filter_question_top ul button:nth-child(2).active:after {
    background: #feb47078;
  }
  ._darkMode_ .filter_question_top ul button:nth-child(3).active:after,
  ._lightMode_ .filter_question_top ul button:nth-child(3).active:after {
    background: #fcb2b2;
  }
  ._darkMode_ .filter_question_top ul button:nth-child(4).active:after,
  ._lightMode_ .filter_question_top ul button:nth-child(4).active:after {
    background: #007aa0;
  }

  ._darkMode_ .filter_question_top ul button.active,
  ._lightMode .filter_question_top ul button.active {
    color: #000 !important;
  }
  .question-main-box-style {
    width: auto !important;
  }
  .quiz_top_header_button p {
    font-size: 12px !important;
    margin: 0px 5px !important;
    width: 25px !important;
    height: 25px !important;
  }
  ._lightMode_ .filter_question_top ul button {
    border-bottom: none !important;
  }
  ._lightMode_ .filter_question_top ul button {
    color: #000 !important;
  }
  ._lightMode_ .question-left a {
    color: #000 !important;
  }
  .App .pb-120 {
    padding-bottom: 60px !important;
  }
  .App .pt-100 {
    padding-top: 50px;
  }
  .page-not-found-content h4 {
    font-size: 50px !important;
  }
  .intro_mobile_view_style {
    margin-top: 30px;
    padding: 0px 10px;
  }
  .intro_mobile_view_style h4 {
    text-align: center;
  }
  .mobile-view.accordion_mobile_view_style {
    overflow-y: hidden;
    overflow-x: auto;
    margin-bottom: 30px;
  }
  .accordion_mobile_view_style_1 {
    display: inline-flex;
    width: auto;
    margin-bottom: 25px;
    margin-left: 20px;
  }
  .accordion_mobile_view_style_1 a {
    margin: 10px 10px 0 0 !important;
  }
  .accordion_mobile_view_style_1 a {
    margin: 10px 10px 0 0 !important;
    font-size: 14px;
    min-width: 140px;
    padding: 10px;
    width: 100%;
  }
  .mobile-view-color-1 .full-width button {
    border-radius: 8px !important;
    background: linear-gradient(180deg, #e5f2d4 0%, #fff 100%);
  }
  .mobile-view-color-1 .full-width .accordion-button:not(.collapsed) {
    background: linear-gradient(180deg, #e5f2d4 0%, #fff 100%);
  }
  .mobile_view_center_box .questionbank-filter-top-heading {
    border-radius: 13px;
    background: linear-gradient(180deg, #dff0f7 0%, #fff 100%);
    padding: 20px 15px;
  }
  .mobile_view_center_box {
    justify-content: center;
  }
  .mobile_view_center_box .popup-button-question-page button {
    max-width: 100% !important;
    margin-right: 0px !important;
    text-align: center !important;
    height: 35px !important;
    font-size: 16px !important;
  }
  .accordion-body {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
  }
  .mobile_view_center_box #example-collapse-text h4 {
    font-size: 18px;
  }
  .mobile_view_style {
    text-align: center;
  }
  .payment_month_and_year_button .form-check.form-check-inline:nth-child(2) {
    padding-right: 0;
  }
  .payment_month_and_year_button
    .form-check.form-check-inline:nth-child(2):before {
    display: none;
  }
  .payment_month_and_year_button
    .form-check.form-check-inline:nth-child(1):before {
    right: 18px !important;
  }
  .payment_month_and_year_button
    .form-check.form-check-inline:nth-child(3):before {
    right: 18px !important;
  }
  .payment_month_and_year_button .form-check-inline {
    min-width: 145px;
  }
  .payment_month_and_year_button .form-check.form-check-inline:nth-child(3) {
    margin-left: 0rem;
    margin-bottom: 10px;
  }
  .payment_month_and_year_button .form-check.form-check-inline:nth-child(4) {
    margin-left: 0rem;
    margin-bottom: 10px;
  }
  .payment_month_and_year_button .form-check.form-check-inline {
    margin: 10px 0px;
  }
  .payment_month_and_year_button {
    height: 100px !important;
  }
  .payment_month_and_year_button .form-check-label {
    margin-left: -10px !important;
  }
  .Pagenotfound_hingh_full {
    height: 59vh !important;
  }
  .background-img-notfound.mobile-view {
    display: flex;
    align-items: flex-end;
  }
  .Pagenotfound {
    height: 347px !important;
  }
  .Pastpaperexamcontent-page-filter .dropdown {
    margin-bottom: 6px;
  }
  .Pastpaperexam-tab-main .nav-tabs.nav-item .nav-link.active {
    background: #fff !important;
    border: 1px solid #7ac719;
  }
  .Pastpaperexam-tab-main.mobile-view {
    flex-wrap: wrap;
    min-height: auto;
    overflow: unset;
    position: absolute;
    left: 0;
  }
  /* .Pastpaperexam-tab-main.mobile-view::after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.24);
    height: 100vh;
    width: 100%;
    z-index: 1;
  } */
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(2) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(3) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(4) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(5) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(6) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(7) .nav-link,
  .Pastpaperexam-tab-main.mobile-view .nav-tabs.nav-item:nth-child(8) .nav-link,
  .Pastpaperexam-tab-main.mobile-view
    .nav-tabs.nav-item:nth-child(9)
    .nav-link {
    left: 0px;
    min-width: 280px;
  }
  .Pastpaperexam-tab-main.mobile-view .nav-link.active {
    min-width: 300px !important;
    padding: 14px;
    border-radius: 0 15px 15px 0;
    z-index: 22 !important;
  }
  .Pastpaperexam-tab-main.mobile-view .nav-link {
    min-width: 280px;
    border-radius: 0 15px 15px 0;
  }
  .tab-menu-icon-left .tab-menu-icon-right {
    display: none;
  }
  .tab-menu-icon-left .tab-menu-icon-left {
    display: block;
  }
  .tab-menu-icon-right .tab-menu-icon-right {
    display: block;
  }
  .tab-menu-icon-right .tab-menu-icon-left {
    display: none;
  }
  .tab-button-menu-show-and-hide {
    position: absolute;
    z-index: 99;
    left: 320px;
    margin-top: 10px;
  }
  .hide-tab-menu {
    display: none;
  }
  .tab-button-menu-show-and-show {
    margin-top: 20px;
  }
  .tab-button-menu-show-and-show button {
    border: none;
    background-color: #0000;
  }
  .tab-button-menu-show-and-hide button {
    border: none;
    background-color: #0000;
  }
  .tab-button-menu-show-and-show button img {
    width: 38px;
  }
  .Pastpaperexam-tab-main.mobile-view .nav-tabs {
    border: none;
  }
  .Pastpaperexam-tab-main.mobile-view .btn-close {
    --bs-btn-close-bg: url("../src/img/svg/right-icon.svg");
    padding: 0;
    margin: 0;
    opacity: 1 !important;
    width: 45px;
    height: 45px;
    background-position: center;
    border-radius: 50%;
    background-size: contain;
    box-shadow: rgb(255 255 255 / 25%) 0px 30px 60px -12px inset,
      rgb(255 255 255 / 30%) 0px 18px 36px -18px inset;
  }
}
@media only screen and (min-width: 992px) {
  .mobile-view {
    display: none !important;
  }
}
/* paper image width and hight set auto */
#example-collapse-text img {
  width: 100% !important;
  height: auto !important;
}

.object_pdf {
  height: auto !important;
}
