._darkMode_ .drack {
  display: none;
}
._lightMode_ .light {
  display: none;
}
.navbar-brand img {
  width: 281px;
  height: 67px;
}
.header_top_search_bar img {
  width: 20px;
  height: 20px;
}
.header_top_search_bar {
  align-items: center;
  border-radius: 5px;
  border: 1px solid #aaa;
  background: #fff;
  padding: 0 6px;
}
.header_top_search_bar input {
  border: none;
  width: 283px;
  height: 29px;
  padding-left: 5px;
  background: #0000;
}
.header_top_search_bar input::placeholder {
  color: #aaa;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.list-unstyled.megamenu-heading-top li a {
  display: flex;
  align-items: center;
}

/* drop down menu */
.navbar-expand-lg .navbar-nav .dropdown-menu {
  max-width: 100%;
  width: 100%;
}
#megamneu {
  color: #5b9311;
  text-align: center;
  font-family: Nunito;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: baseline;
}
.dropdown-toggle::after {
  background-image: url(../../img/icon/dropdown.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  width: 12px;
  height: 18px;
}
.megamenu {
  margin-right: 27px;
  position: unset !important;
}
@media only screen and (max-width: 600px) {
  .navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: unset !important;
  }
  .megamenu {
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    width: auto;
  }
  .header-center-content {
    overflow-y: unset;
    display: contents !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 132px;
    left: 0;
    background: rgb(0 0 0 / 50%);
    height: 100vh;
  }
  .tab-content {
    height: 80vh;
  }
  .navbar-expand-lg {
    height: 133px;
  }
}
.mobile-button {
  background-color: #fff !important;
}
.header-right {
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: initial !important;
  justify-content: center;
}
.header-right img {
  width: 46px;
  height: 46px;
  margin: 0 0.5rem;
}
.header-right a {
  color: #5b9311;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.header-right a:hover {
  color: #5b9311;
}
.Profile-dropdown {
  border-radius: 8px;
  background: #5b9311 !important;
  width: 82px;
  color: #fff !important;
}
._darkMode_ .navbar {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}
._lightMode_ .navbar {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.megamenu-top-tabs {
  border: none !important;
  flex: 0 0 auto;
  margin-left: 16.66666667%;
}
.megamenu-top-tabs li button {
  border: none !important;
  padding-right: 20px !important;
}
.megamenu-top-tabs li button.nav-link.active {
  color: #5b9311;
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.megamenu-top-tabs li button.nav-link {
  text-align: center;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.megamenu-top-tabs li button:after {
  content: '';
  background-image: url(../../img/icon/black-dropdown.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none !important;
  width: 12px;
  height: 18px;
  position: absolute;
  margin-top: -8px;
  right: 0;
}
.megamenu-top-tabs li button.nav-link.active:after {
  background-image: url(../../img/icon/dropdown.png);
}
.megamenu-top-tabs li button {
  margin-right: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.megamenu-left-tab a img {
  width: 18px;
}
.megamenu-left-tab a {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* .navbar-nav .nav-link, .navbar-nav .nav-link.show {
    text-align: center;
    font-family: Nunito;
    padding-left: 16px !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    height: 50px;
} */
.megamenu-left-tab a {
  text-align: center;
  font-family: Nunito;
  padding-left: 16px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  height: 50px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #c1dba0 !important;
  border-radius: 0;
  color: #000 !important;
  text-align: center;
  font-family: Nunito;
  padding-left: 16px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  height: 50px;
}
.top-content-tab-to-tab {
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.megamenu-heading-top li a {
  padding-left: 0 !important;
  margin-left: 0;
  text-align: left;
}
.top_heading {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.megamenu-heading-top li a {
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.megamenu-heading-top li a img {
  width: 21px;
  margin-right: 5px;
}
._lightMode_ .header_top_search_bar {
  background: #fff0;
}
._lightMode_ .drack-mode-header-drop-down {
  background: #2e2e2e;
}
._lightMode_ .nav-pills .nav-link.active,
._lightMode_ .nav-pills .show > .nav-link {
  background: #5b9311 !important;
  color: #fff !important;
}
._lightMode_ .megamenu-left-tab a {
  color: #fff;
}
._lightMode_ .nav-tabs .nav-item.show .nav-link,
._lightMode_ .nav-tabs .nav-link.active {
  background: #0000;
}
._lightMode_ .megamenu-top-tabs li button {
  color: #fff;
}
._lightMode_ .top_heading {
  color: #fff;
}
._lightMode_ .megamenu-heading-top li a {
  color: #fff;
}
._lightMode_ .w {
  display: block;
}
.d {
  display: block;
}
.w {
  display: none;
}
._lightMode_ .megamenu-top-tabs li button:after {
  content: '';
  background-image: url(../../img/icon/header_top_arror_bottom_w.png);
}
/* header left first child active */
/* #uncontrolled-tab-example-tabpane-ap .row .col-md-2:nth-child(1) .navbar-nav .megamenu-left-tab a {
  background: #c1dba0 !important;
  border-radius: 0;
  color: #000 !important;
  text-align: center;
  font-family: Nunito;
  padding-left: 16px !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  height: 50px;
} */
#dropdown-autoclose-false:after {
  display: none;
}
#dropdown-autoclose-false {
  background: #0000;
  border: none;
  margin: 0 !important;
  padding: 0 !important;
  background-color: #0000 !important;
  border-color: #0000 !important;
}

/* new style */
.Profile-dropdown.join-now.nav-link {
  width: 100px;
  margin-left: 20px;
}
