@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container-fluid {
  max-width: 1920px;
}
.pl-0 {
  padding-left: 0 !important;
}
.pr-0 {
  padding-right: 0 !important;
}
img {
  width: 100%;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pt-120 {
  padding-top: 120px;
}
.pb-120 {
  padding-bottom: 120px !important;
}
.pt-100 {
  padding-top: 100px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pt-30 {
  padding-top: 30px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mt-60 {
  margin-top: 60px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mt-30 {
  margin-top: 30px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-10 {
  margin-bottom: 10px;
}
a {
  text-decoration: none !important;
}
/* p {
    margin-bottom: 0 !important;
} */
.align-items {
  align-items: center;
}
.border-remove {
  border: none;
}
.flex-end {
  align-items: flex-end;
}

._lightMode_ .d {
  display: none !important;
}
._lightMode_ .w {
  display: block;
}
.d {
  display: block;
}
.w {
  display: none;
}

/****************** VARIABLES ******************/

:root {
  --background-color-white: #ffffff;
  --background-color-black: #000000;
  --primary-color-text: #5b9311;
  --secondary-color-text: #bae287;
  --font-family: Nunito;
}
._darkMode_ {
  background-color: #fff;
}
._lightMode_ {
  background-color: #2e2e2e;
}
.form-control:focus {
  color: none;
  background-color: none;
  border-color: none;
  outline: 0 !important;
  box-shadow: none !important;
}
.text-end {
  text-align: end;
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    /* max-width: 1278px !important; */
    max-width: 1422px !important;
  }
}
/* button sun and moon start */
.sun-moon {
  border-radius: 50%;
  background: #000;
  padding: 4px 5px;
  margin-top: 20px;
  margin-right: 20px;
}
.sun-moon img {
  width: 24px;
  height: 24px;
}
._lightMode_ .sun-moon {
  background: #fff;
}

/* end button */

/* sun day style start */

._lightMode_ {
}
.Privacy-Policy .key-concept-top-right img {
  width: 150px;
}
.Privacy-Policy-content p {
  color: #000;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 109.9%; /* 19.782px */
}
.Privacy-Policy .ap-heading h2 {
  color: #000;
  font-family: Nunito;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
._lightMode_ .Privacy-Policy .ap-heading h2 {
  color: #fff;
}
.filter-item {
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.grid {
  position: relative !important;
  height: auto !important;
}
/* mobile view */
@media only screen and (max-width: 767px) {
  .payment-box {
    margin-bottom: 2rem;
  }
  .upi-payment a {
    margin-bottom: 2rem;
  }
  .payment-details {
    width: 100%;
  }
  .righ-img-other-payment img {
    height: auto;
    width: 100%;
  }
  .ap-box {
    margin-bottom: 2rem;
  }
}
.filter_myprofile_top ul .active {
  position: relative;
  color: #5b9311 !important;
}
.filter_myprofile_top ul .active:before {
  bottom: -21px;
  border-bottom: 1px solid #5b9312;
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
}
.filter_question_top ul {
  margin-bottom: 0;
}
.filter_question_top ul button {
  position: relative;
  /* border-bottom: 2px solid #000; */
  border-bottom: none;
  /* padding-bottom: 1rem; */
}
._lightMode_ .filter_question_top ul button {
  /* border-bottom: 2px solid #fff; */
}
/* .filter_question_top ul .active {
    color: #5B9311 !important;
} */

/* button */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  border-color: #0000 !important;
}
.modal-dialog {
  pointer-events: auto !important;
}
.qu-box {
  margin-bottom: 1.5rem;
}
.quiz-box {
  margin-bottom: 1.5rem;
}
.quiz_question_top img {
  width: 100% !important;
  height: auto !important;
  margin: 2rem;
}
.form-check-label img {
  width: 100% !important;
  height: auto !important;
  max-width: 200px;
}
.fixed-accordion {
  margin-right: 10px;
  position: sticky;
  top: 70px;
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

/* pagenotfounf page style other page  */
.fixed-accordion
  .Pagenotfound
  .page-not-found-content
  .page-not-found-to-go-back {
  margin-top: 0;
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;
}
.fixed-accordion .Pagenotfound .page-not-found-content h2 {
  font-size: 16px;
}
.fixed-accordion .Pagenotfound {
  height: 220px;
}
